<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'migracao-turmas-matriculas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Migrar Matrículas da Turma
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          color="primary"
          @click="confirmarMigracaoMatriculas"
          :disabled="matriculasSelecionadas.length === 0"
        >
          <v-icon left>mdi-account-arrow-right</v-icon>
          Migrar Matrículas Selecionadas
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>

        <v-col cols="12" v-if="turma">
          <v-card class="mb-5" outlined color="grey lighten-4">
            <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
            <v-card-subtitle class="font-weight-medium">
              <div>Total de alunos: {{ matriculas.length || "- - -" }}</div>
              <div>Tipo de atendimento: {{ turma.tipo_de_atendimento || "- - -" }}</div>
            </v-card-subtitle>
          </v-card>
        </v-col>

        <v-card-text>
          <v-alert v-if="matriculas.length === 0" type="info">
            Nenhuma matrícula encontrada nesta turma.
          </v-alert>

          <v-simple-table v-else class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <v-checkbox
                      v-model="todosSelecionados"
                      @change="selecionarTodos"
                      :indeterminate="algunsSelecionados"
                    ></v-checkbox>
                  </th>
                  <th class="text-left">Nº</th>
                  <th class="text-left">Aluno</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(matricula, index) of matriculas"
                  :key="index"
                  :class="[
                    $constants.situacoesMatriculaCondition[
                      removerEspaco(matricula.situacao ? matricula.situacao : '')
                    ]
                      ? 'red lighten-4'
                      : null,
                    matricula.migrada ? 'grey lighten-2' : '',
                  ]"
                >
                  <td width="20">
                    <v-checkbox
                      v-model="matriculasSelecionadas"
                      :value="matricula.id"
                      :disabled="
                        matricula.migrada ||
                        $constants.situacoesMatriculaCondition[
                          removerEspaco(matricula.situacao ? matricula.situacao : '')
                        ]
                      "
                    ></v-checkbox>
                  </td>
                  <td width="20">{{ index + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <v-row class="py-5">
                      <v-skeleton-loader v-if="!matricula.imagem" type="avatar"></v-skeleton-loader>
                      <v-avatar v-else size="48px">
                        <img alt="Avatar" :src="matricula.imagem" />
                      </v-avatar>
                      <div class="ml-2">
                        <span>{{ matricula.aluno.nomecompleto }}</span>
                        <br />
                        <small>
                          {{ matricula.aluno.codigo_inep || "- - -" }} /
                          {{ matricula.aluno.codigo || "- - -" }}
                        </small>
                        <br />
                        <small
                          v-if="matricula.migrada"
                          class="d-flex align-items-center"
                          style="color: #4caf50"
                        >
                          <v-icon small class="mr-1" style="color: #4caf50"
                            >mdi-check-circle</v-icon
                          >
                          Matrícula Migrada
                        </small>
                      </div>
                    </v-row>
                  </td>

                  <td v-if="$can(['matriculas.rfid'])">
                    {{ matricula ? matricula.codigo_frid : "" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>

    <!-- Modal de Confirmação -->
    <v-dialog v-model="dialogConfirmarMigracaoMatriculas" max-width="500px">
      <v-card>
        <v-card-title class="headline">Migrar Matrículas</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pt-4 pb-0" cols="12">
              <p class="font-weight-regular">
                <v-select
                  v-model="turmaSelecionada"
                  :items="turmasAnoSeguinte"
                  item-text="descricao"
                  item-value="id"
                  label="Selecione uma turma"
                ></v-select>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          Tem certeza que deseja migrar as {{ matriculasSelecionadas.length }} matrículas
          selecionadas?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogConfirmarMigracaoMatriculas = false"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="migrarMatriculasConfirmada" :loading="loading"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      turmaSelecionada: {},
      turmasAnoSeguinte: [],
      turma: null,
      matriculas: [],
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
      ],
      isHidden: false,
      imagens: [],
      aluno: null,
      dialogConfirmarMigracaoMatriculas: false,
      loading: false,
      matriculasSelecionadas: [],
      todosSelecionados: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),

    algunsSelecionados() {
      return (
        this.matriculasSelecionadas.length > 0 &&
        this.matriculasSelecionadas.length < this.matriculas.length
      );
    },
  },
  watch: {
    matriculasSelecionadas(val) {
      this.todosSelecionados = val.length === this.matriculas.length;
    },
  },
  mounted() {
    this.loadBasicData();
    this.buscarTurmasAnoSeguinte();
  },
  methods: {
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");

      const payload = await this.$services.turmasService.visualizarTurma(
        parseInt(this.$route.params.turma_id, 10)
      );

      this.turma = payload.turma;

      this.isHidden = payload.turma.sistema_bncc_id === 1;
      this.matriculas = payload.matriculas.map((matricula) => ({
        ...matricula,
        migrada: matricula.migrada || false,
      }));

      this.$loaderService.close();
      await payload.matriculas.forEach(async (matricula, index) => {
        const imagem = await this.verImage(matricula.aluno.id);
        matricula.imagem = imagem;
        this.$set(this.matriculas, index, matricula);
      });
      this.$forceUpdate();
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async verImage(aluno_id) {
      return this.$services.imageService.downloadImage(
        `notifiq/matriculas/download-image-aluno/${aluno_id}`
      );
    },
    confirmarMigracaoMatriculas() {
      this.dialogConfirmarMigracaoMatriculas = true;
    },
    async migrarMatriculasConfirmada() {
      this.loading = true;
      try {
        const response = await this.$services.matriculasService.migrarMatriculas(
          this.turmaSelecionada,
          this.matriculasSelecionadas
        );
        this.$toast.success(response.message);
        this.dialogConfirmarMigracaoMatriculas = false;

        this.matriculas.forEach((matricula) => {
          if (this.matriculasSelecionadas.includes(matricula.id)) {
            matricula.migrada = true;
          }
        });

        this.matriculasSelecionadas = [];
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.loading = false;
      }
    },

    selecionarTodos() {
      if (this.todosSelecionados) {
        this.matriculasSelecionadas = this.matriculas
          .filter(
            (matricula) =>
              !this.$constants.situacoesMatriculaCondition[
                this.removerEspaco(matricula.situacao ? matricula.situacao : "")
              ] && !matricula.migrada // Adicionando a verificação para matrículas já migradas
          )
          .map((matricula) => matricula.id);
      } else {
        this.matriculasSelecionadas = [];
      }
    },

    async buscarTurmasAnoSeguinte() {
      try {
        const response = await this.$services.turmasService.getTurmasAnoSeguinte(this.user.ano_id);

        this.turmasAnoSeguinte = response.turmas;
      } catch (error) {
        this.$toast.danger("Erro ao buscar turmas do ano seguinte.");
      }
    },
  },
};
</script>
