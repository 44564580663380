<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.push({ name: 'home' })">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Planos Infantis
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-form>
          <v-card>
            <div class="text-start py-2 px-4 text-button">Pesquisa avançada</div>

            <v-divider class="theme--light"></v-divider>
            <v-row class="px-4 mt-4">
              <v-col v-if="permitirRolesParaGestores() >= 0">
                <v-text-field
                  v-model="professor"
                  label="NOME ou CPF Professor"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between mb-6 px-4">
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="disciplinas"
                  :return-object="false"
                  v-model="disciplina"
                  label="Escolha uma disciplina"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="turmas"
                  :return-object="false"
                  v-model="turma"
                  label="Escolha uma turma"
                  item-text="descricao"
                  item-value="descricao"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  :items="$constants.mesesPlanoMensal"
                  :return-object="false"
                  v-model="mes"
                  label="Escolha um mês"
                  item-text="descricao"
                  item-value="id"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12" class="mb-5">
                <v-btn color="primary" @click="() => limparCampos()" block>Limpar filtros</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>

      <v-col>
        <v-data-table
          :headers="table.headers"
          :items="planos"
          :loading="carregando"
          :items-per-page="15"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="plano of items" :key="plano.id">
                <td>{{ plano.id ? plano.id : "- - -" }}</td>
                <td>
                  {{ plano.gestao_de_aula.professor.nome }}
                </td>
                <td>{{ plano.gestao_de_aula.turma.descricao }}</td>
                <td>
                  <e-situacao-plano-infantil
                    :plano="plano"
                    @update="(e) => carregarDadosAoAtualizarStatus(e)"
                  />
                </td>
                <td>{{ plano.mes ? plano.mes.descricao : "" }}</td>
                <td>{{ plano.gestao_de_aula.disciplina?.descricao }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="
                          () =>
                            $router.push({
                              name: 'gestoesDeAulas.planosInfantilVisualizar',
                              params: { id: plano.id },
                            })
                        "
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="orange"
                        v-on="on"
                      >
                        <v-icon small>mdi-note-text-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="() => [visualizarPdf(plano.id), (dialog = true)]"
                        x-small
                        class="ml-1"
                        v-bind="attrs"
                        color="orange"
                        v-on="on"
                      >
                        <v-icon small>mdi-note </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar Pdf</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <v-snackbar v-model="mostrarMensagem" color="primary">
          <strong>Você não pode alterar o status do plano.</strong>
        </v-snackbar>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="carregarPlanos"
          :search="search"
        >
        </e-paginate>
      </v-col>
    </v-row>

    <ModalCarteira :dialog="dialog" :pdf="pdf" v-on:update="carregarDadosAoAtualizarStatus" />
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import ModalCarteira from "@/components/Matriculas/EModalCarteira.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalCarteira,
  },
  watch: {
    async professor(value) {
      value = this.formatarCPf(value);
      this.professor = value;
      await this.carregarPlanos();
    },
    async turma(value) {
      this.turma = value;
      await this.carregarPlanos();
    },
    async disciplina(value) {
      this.disciplina = value;
      await this.carregarPlanos();
    },
    async mes(value) {
      this.mes = value;
      await this.carregarPlanos();
    },
    async bimestre(value) {
      this.bimestre = value;
      await this.carregarPlanos();
    },
  },
  data() {
    return {
      carregando: false,
      dialog: false,
      pdf: null,
      disciplinas: [],
      turmas: [],
      planos: [],
      bimestres: [],
      meses: [],
      bimestre: "",
      professor: "",
      turma: "",
      disciplina: "",
      mes: "",
      periodo: "",
      mostrarMensagem: false,
      dataTableFilter,
      search: "",
      pageNumber: 1,
      paginate: {
        current_page: 1,
        last_page: 1,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Professor", value: "gestao_de_aula.professor.nome" },
          { text: "Turma", value: "gestao_de_aula.turma.descricao" },
          { text: "Status", value: "status" },
          { text: "Mês", value: "mes" },
          { text: "Disciplina", value: "gestao_de_aula.disciplina.descricao" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  async mounted() {
    this.carregarDados();

    this.permitirRolesParaGestores();
  },
  methods: {
    async carregarDados() {
      this.carregarPlanos(this.pageNumber);
      this.listarDisciplinas();
      this.listarTurmas();
      this.listarMeses();
    },
    permitirRolesParaGestores() {
      try {
        // const userLocal = this.user?.role ? this.user.role.name : [];
        // return this.$constants.rolesParaGestores.findIndex((elem) => {
        //   return elem.name === userLocal;
        // });
        const userLocal = this.user.roles ? this.user.roleName : [];
        return this.$constants.rolesParaGestores.findIndex((elem) => {
          return elem.name === userLocal;
        });
      } catch (error) {
        this.$handleError(error);
      }
      return false;
    },
    async listarDisciplinas() {
      try {
        const { data } = await this.$services.planosService.disciplinas();
        this.disciplinas = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarTurmas() {
      try {
        const { data } = await this.$services.planosService.turmasInfantil();
        this.turmas = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async listarMeses() {
      try {
        const { data } = await this.$services.planosService.meses();
        this.meses = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async carregarPlanos(numeroDaPagina = null) {
      this.paginate.current_page = numeroDaPagina || this.pageNumber;
      this.pageNumber = numeroDaPagina || this.pageNumber;
      try {
        this.dialog = false;
        this.carregando = true;
        const payload = await this.$services.planosService.indexInfantil(
          this.pageNumber,
          this.professor,
          this.turma,
          this.disciplina,
          this.mes
        );

        this.planos = payload.planos;
        this.paginate = payload.data;
        this.carregando = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async carregarDadosAoAtualizarStatus(plano) {
      try {
        this.carregarPlanos();
      } catch (error) {
        this.$handleError(error);
      }
    },
    limparCampos() {
      this.professor = "";
      this.turma = "";
      this.disciplina = "";
      this.mes = "";
    },
    formatarCPf(value) {
      value = value.replace("-", "");
      value = value.replace(".", "");
      return value;
    },
    async visualizarPdf(planoId) {
      this.pdf = await this.$services.planosService.pdfInfantil(planoId);
    },
    async mensagem(e) {
      if (!e) {
        this.mostrarMensagem = true;
      }
    },
  },
};
</script>
<style></style>
