import Axios from "@/plugins/Axios";

export class AnosService {
  async syncAll() {
    if (this.syncAll.anos) return this.syncAll.anos;
    const response = await Axios().get("anos");
    const anos = response.data;
    this.syncAll.anos = anos;
    return anos;
  }

  async meses() {
    const response = await Axios().get("meses");
    const meses = response.data;
    return meses;
  }

  async getAno(ano_id) {
    const response = await Axios().get(`anos/${ano_id}`);
    const anos = response.data;
    return anos;
  }
}
export default new AnosService();
