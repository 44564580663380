<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex justify-space-between">
            <v-btn
              :to="{
                name: 'avisos.create',
              }"
              color="success"
              class="mr-2"
              >Criar Aviso</v-btn
            >
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="avisos"
          :loading="carregandoAvisos"
          :items-per-page="10"
          hide-default-footer
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr color="primary" v-for="aviso in items" :key="aviso.id">
                <td>{{ aviso.id }}</td>
                <td>{{ aviso.titulo }}</td>
                <td>{{ aviso.contexto }}</td>
                <template v-for="(tipo, index) in tipos">
                  <td class="text-capitalize" v-if="aviso[tipo]" :key="index">
                    <e-resumo-avisos-snippet
                      :tipo="aviso[tipo] ? (tipo === 'configuracoes' ? 'Escolas' : tipo) : '- - -'"
                      :items="aviso['description_' + tipo]"
                    />
                  </td>
                </template>
                <td>
                  <v-chip
                    style="width: 80px; justify-content: center"
                    :color="situacoes[aviso.status] ? situacoes[aviso.status].color : ''"
                    outlined
                    label
                    small
                  >
                    {{
                      aviso.status
                        ? situacoes[aviso.status]
                          ? situacoes[aviso.status].descricao
                          : "- - -"
                        : "- - -"
                    }}
                  </v-chip>
                </td>
                <td width="25%">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => enableSubmit(aviso.id)"
                      >
                        <span class="material-icons"> notifications </span>
                      </v-btn>
                    </template>
                    <span>Notificar</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="verAviso(aviso.id, aviso)"
                      >
                        <span class="material-icons"> visibility </span>
                      </v-btn>
                    </template>
                    <span>Visualizar aviso</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'avisos.edit',
                          params: { aviso_id: aviso.id },
                        }"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => destroy(aviso.id)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Deletar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="loadData"
          :search="search"
        ></e-paginate>
        <e-modal-confirm
          :confirmDialog="confirmDialog"
          :objectModal="objectModal"
          @changeModalConfirm="enableSubmit"
          @changeActionResponse="notificar"
        >
        </e-modal-confirm>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="dialog" width="374">
        <v-card class="mx-auto" max-width="374">
          <template v-if="!aviso">
            <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
              <v-skeleton-loader class="mx-auto" max-width="374" type="card"></v-skeleton-loader>
            </v-sheet>
          </template>

          <template v-else>
            <!-- Verifica se há uma imagem disponível -->
            <v-img v-if="url_image_aviso" :src="url_image_aviso" height="200px"></v-img>
            <!-- Se não houver imagem, exibe uma mensagem -->
            <span v-else class="d-flex justify-center font-weight-bold pa-3"
              >Nenhuma imagem disponível</span
            >
            <v-card-title>{{ aviso.titulo || "- - -" }}</v-card-title>
            <v-card-subtitle>{{ aviso.contexto || "- - -" }}</v-card-subtitle>
            <v-card-actions>
              <v-btn @click="dialog = false" color="orange lighten-2" text>Fechar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog>
    </div>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      dataTableFilter,
      avisos: [],
      objectModal: {
        title: "Atenção",
        message: "Tem certeza que deseja enviar este aviso?",
      },
      confirmDialog: false,
      carregandoAvisos: false,
      search: "",
      pageNumber: 0,
      aviso_id: null,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      situacoes: {
        0: {
          descricao: "Ativo",
          color: "blue lighten-1",
        },
        1: {
          descricao: "Inativo",
          color: "red lighten-1",
        },
        2: {
          descricao: "Enviado",
          color: "green lighten-1",
        },
      },
      item: {
        aluno: "nomecompleto",
        turma: "descricao",
        franquia: "descricao",
      },
      tipos: ["alunos", "turmas", "configuracoes"],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Titulo", value: "titulo" },
          { text: "Contexto", value: "contexto" },
          { text: "Tipo", value: "tipo" },
          { text: "Status", value: "status" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
      aviso: null,
      url_image_aviso: "",
      dialog: false,
    };
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  watch: {
    async search(val) {
      if (val.length > 2) {
        await this.loadData(0, val);
      }
      if (!val) {
        await this.loadData(0);
      }
    },
  },
  methods: {
    enableSubmit(aviso_id) {
      this.confirmDialog = !this.confirmDialog;
      this.aviso_id = aviso_id;
    },

    async loadData(pageNumber, query = null) {
      const response = await this.$services.avisosService.syncAll(pageNumber, query);
      this.paginate = response;
      this.avisos = response.data;
    },

    async notificar() {
      this.$loaderService.open("Enviando avisos...");
      try {
        await this.$services.avisosService.notificar(this.aviso_id);
        this.loadData(this.pageNumber);
        this.confirmDialog = !this.confirmDialog;
        this.$toast.success("Aviso enviado com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async destroy(aviso_id) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar este aviso?",
        confirmationCode: aviso_id,
        confirmationMessage: `Por favor, digite <strong>${aviso_id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o aviso");
              try {
                await this.$services.avisosService.destroy(aviso_id);
                this.loadData(this.pageNumber);
                this.$toast.success("Aviso deletado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async verAviso(aviso_id, aviso) {
      this.dialog = !this.dialog;
      this.$forceUpdate();
      try {
        this.url_image_aviso = await this.$services.imageService.downloadImage(
          `notifiq/avisos/download-image/${aviso_id}`
        );
      } catch (error) {
        this.url_image_aviso = null;
      }
      this.aviso = aviso;
    },
  },
  mounted() {
    this.loadData(this.pageNumber);
  },
};
</script>

<style></style>
