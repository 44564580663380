<template>
  <main-template>
    <v-row>
      <v-col>
        <v-btn color="primary" x-small text @click="() => $router.push({ name: 'home' })">
          <v-icon left> fa fa-angle-left </v-icon>
        </v-btn>
        <span class="text-h4">Migrações </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-card-text>
            <e-label>Selecione uma franquia aqui</e-label>
            <ValidationProvider name="Franquia" rules="required" v-slot="{ errors }">
              <e-autocomplete
                :error-messages="errors"
                :items="franquias.filter((item) => !item.deleted_at)"
                :item-text="(item) => item.descricao"
                return-object
                v-model="franquiaSelecionada"
              />
            </ValidationProvider>
          </v-card-text>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="primary"
            @click="confirmarMigracaoTodasTurmas"
            :disabled="!franquiaSelecionada || !proximoAnoExiste"
          >
            Migrar Todas as Turmas
          </v-btn>
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :loading="carregandoTurmas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                :class="parseInt(turma.notifiq, 10) === 1 ? 'green lighten-5' : ''"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>{{ turma.codigo }}</td>
                <td>
                  {{ turma.descricao }}
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip label v-bind="attrs" v-on="on" color="primary" x-small> S </v-chip>
                    </template>
                    <span
                      v-html="
                        `Séries: ${turma.series.length} unidades <br>` +
                        turma.series.map((serie) => serie.descricao).join('<br />')
                      "
                    ></span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        label
                        v-bind="attrs"
                        v-on="on"
                        color="secondary"
                        x-small
                        v-if="turma.multi"
                        class="ml-1"
                      >
                        M
                      </v-chip>
                    </template>
                    <span>DISCIPLINA MULTI</span>
                  </v-tooltip>
                </td>
                <td class="text-center">{{ turma.matriculas_count }}/{{ turma.qtd }}</td>
                <td>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="confirmarMigracao(turma)"
                        :disabled="turma.migrada || !proximoAnoExiste"
                      >
                        <v-icon small>mdi-transfer </v-icon>
                      </v-btn>
                    </template>
                    <span>Migrar Turma</span>
                  </v-tooltip>
                  <v-tooltip v-if="!turma.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => migrarMatriculas(turma)"
                      >
                        <v-icon small>mdi-account-multiple </v-icon>
                      </v-btn>
                    </template>
                    <span>Migrar Matrículas</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          @changeActionResponse="(page) => listarTurmas(page, franquiaSelecionada?.id)"
          :search="search"
          :pageNumber="parseInt(pageNumber)"
        >
        </e-paginate>
      </v-col>
    </v-row>

    <!-- Modal de Confirmação -->
    <v-dialog v-model="dialogConfirmarMigracao" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmação</v-card-title>
        <v-card-text>Tem certeza que deseja migrar essa turma para o ano seguinte?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="color: #fff"
            class="red darken-1"
            text
            @click="dialogConfirmarMigracao = false"
            >Cancelar</v-btn
          >
          <v-btn style="color: #fff" class="primary" text @click="migrarTurmaConfirmada"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Confirmação para Migração em Massa -->
    <v-dialog v-model="dialogConfirmarMigracaoTodasTurmas" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmação</v-card-title>
        <v-card-text
          >Tem certeza que deseja migrar todas as turmas da franquia selecionada para o ano
          seguinte?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="color: #fff"
            class="red darken-1"
            text
            @click="dialogConfirmarMigracaoTodasTurmas = false"
            >Cancelar</v-btn
          >
          <v-btn style="color: #fff" class="primary" text @click="migrarTodasTurmasConfirmada"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Turma from "@/Models/Turma";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquiasList"]),
  },
  data() {
    return {
      franquias: [],
      franquiaSelecionada: null,
      carregandoTurmas: false,
      dialog: false,
      dialogConfirmarMigracao: false,
      dialogConfirmarMigracaoTodasTurmas: false,
      turmaParaMigrar: null,
      proximoAnoExiste: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      turmasList: [],
      turma_id: null,
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código", value: "codigo" },
          { text: "Nome", value: "descricao" },
          { text: "Opções", value: "multi", sortable: false },
          { text: "Quantidade", value: "quantidade", sortable: false },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  mounted() {
    this.carregarFranquias();
  },
  watch: {
    franquiaSelecionada(newFranquia) {
      if (newFranquia) {
        this.listarTurmas(this.pageNumber, newFranquia.id);
      }
    },
    carregandoTurmas(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando turmas");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val.length > 3) {
        await this.listarTurmas(0, val);
      }
      if (!val) {
        await this.listarTurmas(0);
      }
    },
  },
  methods: {
    async carregarFranquias() {
      try {
        this.franquias = await this.$services.franquiasService.syncAll();

        if (this.franquias.length > 0) {
          const franquiaUsuario = this.franquias.find((f) => f.id === this.user.configuracao_id);
          this.franquiaSelecionada = franquiaUsuario || this.franquias[0];
        }
      } catch (error) {
        this.$handleError(error);
      }
    },

    async listarTurmas(pageNumber = 1, franquiaId = this.franquiaSelecionada?.id) {
      this.carregandoTurmas = true;
      this.$loaderService.open("Carregando turmas");

      try {
        const response = await this.$services.turmasService.syncAllPaginateFranquia(
          pageNumber,
          franquiaId
        );

        const ultimoAno = Math.max(...this.turmasList.map((turma) => turma.ano_id));
        const anoResponse = await this.$services.anosService.getAno(ultimoAno);
        this.proximoAnoExiste = anoResponse.exists;
        this.proximoAno = anoResponse.ano;

        this.turmasList = response.data.map((turma) => {
          return {
            ...new Turma(turma),
            migrada: turma.migrada,
          };
        });

        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }

      this.carregandoTurmas = false;
      this.$loaderService.close();
    },

    confirmarMigracao(turma) {
      this.turmaParaMigrar = turma;
      this.dialogConfirmarMigracao = true;
    },
    async migrarTurmaConfirmada() {
      try {
        const response = await this.$services.turmasService.migrarTurma(this.turmaParaMigrar.id);
        this.$toast.success(response.message);
        this.turmaParaMigrar.migrada = true;
        this.turmasList = this.turmasList.map((t) =>
          t.id === this.turmaParaMigrar.id ? { ...t, migrada: true } : t
        );
        this.dialogConfirmarMigracao = false;
      } catch (error) {
        this.$toast.danger(error.message);
      }
    },

    confirmarMigracaoTodasTurmas() {
      this.dialogConfirmarMigracaoTodasTurmas = true;
    },
    async migrarTodasTurmasConfirmada() {
      try {
        const response = await this.$services.turmasService.migrarTodasTurmas(
          this.franquiaSelecionada.id
        );
        this.$toast.success(response.message);
        this.turmasList = this.turmasList.map((turma) => {
          return {
            ...turma,
            migrada: true,
          };
        });
        this.dialogConfirmarMigracaoTodasTurmas = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    migrarMatriculas(turma) {
      this.$router.push({
        name: "migracao-turmas-matriculas.view",
        params: { turma_id: turma.id },
      });
    },
  },
};
</script>

<style></style>
