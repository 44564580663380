<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Pedidos Enviados</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="autorizacoesList"
          :loading="carregandoAutorizacoes"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                v-for="(autorizacao, i) of items"
                :key="i"
                @click="() => openDialog(autorizacao)"
                color="primary"
              >
                <td>{{ autorizacao.id }}</td>
                <td>{{ autorizacao.pedido.descricao }}</td>
                <td>{{ autorizacao.solicitante ? autorizacao.solicitante.name : "" }}</td>
                <td>{{ autorizacao.aprovador ? autorizacao.aprovador.name : "" }}</td>
                <td>{{ autorizacao.etapa ? autorizacao.etapa.descricao : "" }}</td>
                <td v-if="currentUser.id === autorizacao.aprovador.id">
                  <e-situacao-autorizacao
                    :autorizacao="autorizacao"
                    :i="i"
                    @alterarSituacao="alterarSituacao"
                  />
                </td>
                <td v-else>
                  <v-chip label class="text-center" :color="mudarCor(autorizacao.status)" x-small>
                    {{ autorizacao.status }}
                  </v-chip>
                </td>
                <td>
                  {{
                    autorizacao.data_expiracao
                      ? validarData(autorizacao.data_expiracao)
                      : "Tempo Indeterminado"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" width="800">
          <v-card>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                <v-card-title>
                  <span class="text-h5">Detalhes do Pedido</span>
                </v-card-title>
                <v-divider> </v-divider>
                <v-card-text class="mt-5">
                  <v-row>
                    <v-col class="pt-0 pb-0" cols="12">
                      <ValidationProvider name="observacoes" rules="required" v-slot="{ errors }">
                        <e-label>Observações</e-label>
                        <v-textarea solo dense v-model="observacoes" :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                    <v-col>
                      <v-dialog
                        ref="dialogInicial"
                        v-model="modalInicio"
                        :return-value.sync="data_expiracao"
                        persistent
                        width="290px"
                        :retain-focus="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider
                            name="Período Inicial"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Data de Expiração</e-label>
                            <v-text-field
                              v-model="periodo_expiracao"
                              persistent-hint
                              label="Escolha uma data"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              solo
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker v-model="data_expiracao" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modalInicio = false"> Cancel </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialogInicial.save(data_expiracao)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green-darken-1"
                    variant="text"
                    :disabled="submittingForm"
                    :loading="submittingForm"
                    type="submit"
                  >
                    Salvar
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </main-template>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import store from "@/store";
import { dataTableFilter } from "@/plugins/searchQuery";
import { dateFormatParam } from "../../../plugins/filters";
import ESituacaoAutorizacao from "../../../components/Autorizacao/ESituacaoAutorizacao.vue";

export default {
  components: { ESituacaoAutorizacao },
  computed: {
    ...mapGetters("Autorizacoes", [
      "autorizacoes",
      "autorizacoesGeral",
      "carregandoAutorizacoes",
      "autorizacoesList",
    ]),
    form() {
      return {};
    },
  },
  data() {
    return {
      data_expiracao: null,
      observacoes: null,
      tempo_inicial: null,
      tempo_final: null,
      on: null,
      attrs: null,
      periodo_expiracao: null,
      data_final: null,
      modalInicio: false,
      modalFim: false,
      dialog: false,
      autorizacao_id: null,
      submittingForm: false,
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          // { text: "Código", value: "codigo" },
          { text: "Descrição", value: "descricao" },
          { text: "Solicitante", value: "solicitante" },
          { text: "Avaliador", value: "avaliador" },
          { text: "Etapa", value: "etapa" },
          { text: "Situação", value: "situacao" },
          { text: "Validade", value: "validade" },
          // { text: "Contexto", value: "contexto" },
        ],
      },
      currentUser: store.getters["Auth/user"],
    };
  },
  mounted() {
    if (this.currentUser.roles.filter((d) => d.name === "professor").length) {
      this.$store.dispatch("Autorizacoes/loadAutorizacoes", { tipo: "solicitante" });
    } else {
      this.$store.dispatch("Autorizacoes/loadAutorizacoes", { tipo: "aprovador" });
    }
  },
  watch: {
    carregandoAutorizacoes(value) {
      if (value && this.autorizacoes.length === 0) {
        this.$loaderService.open("Carregando Circuitos");
      } else {
        this.$loaderService.close();
      }
    },
    data_expiracao(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(
        new Date(value)
      );
      this.periodo_expiracao = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    mudarCor(status) {
      switch (status) {
        case "PENDENTE":
          return "success";
        case "APROVADO":
          return "green";
        case "CANCELADO":
          return "red";

        default:
          return "";
      }
    },
    openDialog(item) {
      if (item.aprovador.id === this.currentUser.id) {
        this.autorizacao_id = item.id;
        this.observacoes = item.observacoes;
        this.data_expiracao = item.data_expiracao
          ? item.data_expiracao
          : new Date(new Date().getFullYear(), 11, 31).toISOString().split("T")[0];

        this.dialog = true;
      }
    },
    validarData(data) {
      return dateFormatParam(data, "d/m/y");
    },
    criarAutoriaza() {
      this.$router.push({ name: "autorizacoes.create", params: { etapa: etapaId } });
    },
    alterarSituacao(Objeto) {
      this.$set(this.autorizacoesList, Objeto.i, Objeto.aulaAtualizada);
      this.$store.dispatch("Autorizacoes/loadAutorizacoes");
    },
    async submitForm() {
      this.submittingForm = true;
      this.form.id = this.autorizacao_id;
      this.form.observacoes = this.observacoes;
      this.form.data_expiracao = this.data_expiracao;
      // console.log(this.form.data_expiracao);
      try {
        await this.$services.autorizacoesService.atualizarAutorizacao(this.form);
        this.$toast.success("Pedido Atualizado com sucesso");
        this.dialog = false;
        this.$store.dispatch("Autorizacoes/loadAutorizacoes");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
  },
};
</script>

<style></style>
