import TurmasIndexPage from "@/Pages/MigracaoTurmasMatriculas/index.vue";
import TurmasViewPage from "@/Pages/MigracaoTurmasMatriculas/view.vue";

export default [
  {
    path: "/migracao-turmas-matriculas/",
    name: "migracao-turmas-matriculas",
    component: TurmasIndexPage,
  },

  {
    path: "/migracao-turmas-matriculas/view/:turma_id",
    name: "migracao-turmas-matriculas.view",
    component: TurmasViewPage,
  },
];
